@import "node_modules/bootstrap/scss/bootstrap";

$enable-shadows: true;

.splash{
  background-image: url('./srcImages/moneyShotDark.png');
  background-size: cover;
  background-position: center;  

  .titleBlock {
    color:white;

    .title {
      letter-spacing: .4em;
      margin-bottom: 1.3rem;
    }
  }
}

.spring-sale{
  background-image: url('./srcImages/SpringSale.png');
}

.free-shipping{
  background-image: url('./srcImages/FreeShipping.png');
}

.black-friday{
  background-image: url('./srcImages/BlackFridayCropped.png');
}

.christmas{
  background-image: url('./srcImages/redNoSnow2000DarkCropped.png');
}

.presidents-day{
  background-image: url('./srcImages/PresidentsDaySaleWebsite.png');
}

.sale{
  background-size: cover;
  background-position: center;  
  cursor: pointer;

  .title{
    letter-spacing: .5rem;
    font-weight: 400;
  }

  > * {
    color: red;
  }
}

.card {
  .card-media{
    flex: 1 1 275px;
  }

  .card-copy{
    flex: 1 1 275px;
  }
}

@media screen and (max-width: 768px) {
  .card .card-copy{
    order: 2;
  }
}

.tech-spec{ 
  h6{
    margin: 2rem 0 0 0   !important;
  }
  li{
    padding-bottom: .8rem;
  }
}

.contact-info, .sub-footer, .tech-spec{
  font-size:.9rem;
}

.grow-1 {flex: 1}

@include media-breakpoint-up(sm) {
  body {
    font-size: 1.2rem !important;
  }

  .christmas{
    .title{letter-spacing: 1rem;}
  }

  .blackFriday{
    Button{
      font-size: 1rem;
    }
    .copy div{
      margin-top: .75rem;
    }
  }
}

@include media-breakpoint-up(md) {
  body {
    font-size: 1.4rem !important;
  }

  .christmas{
    .title{letter-spacing: 1.5rem;}
  }

  .blackFriday{
    Button{
      font-size: 2rem;
    }
    .copy div{
      margin-top: 1rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  body {
    font-size: 1.6rem !important;
  }

  .blackFriday{
    Button{
      font-size: 3rem;
    }
    .copy div{
      margin-top: 1.6rem;
    }
  }
}